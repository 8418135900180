import { RouterModule, Routes } from '@angular/router';
//Spanish version
import { IndexComponent } from './components/webspanish/index/index.component';
import { MetodologiaComponent } from './components/webspanish/metodologia/metodologia.component'; 
import { NosotrosComponent } from './components/webspanish/nosotros/nosotros.component';
import { TrabajaComponent } from './components/webspanish/trabaja/trabaja.component';
import { ContactoComponent } from './components/webspanish/contacto/contacto.component';
import { ServiciosprogramasComponent } from './components/webspanish/serviciosprogramas/serviciosprogramas.component';
import { ServiciostraduccionComponent } from './components/webspanish/serviciostraduccion/serviciostraduccion.component';
import { ServiciosotrosComponent } from './components/webspanish/serviciosotros/serviciosotros.component';
//English version
import { HomeComponent } from './components/webenglish/home/home.component';
import { MethodologyComponent } from './components/webenglish/methodology/methodology.component';
import { AboutusComponent } from './components/webenglish/aboutus/aboutus.component';
import { WorkwithusComponent } from './components/webenglish/workwithus/workwithus.component';
import { ContactComponent } from './components/webenglish/contact/contact.component';
import { ServicesprogrammesComponent } from './components/webenglish/servicesprogrammes/servicesprogrammes.component';
import { ServicestranslationComponent } from './components/webenglish/servicestranslation/servicestranslation.component';
import { ServicesothersComponent } from './components/webenglish/servicesothers/servicesothers.component'; 

const APP_ROUTES: Routes = [
	{ path: '', component: IndexComponent },
	{ path: 'metodologia', component: MetodologiaComponent },
	{ path: 'programas-de-ensenanza', component: ServiciosprogramasComponent },
	{ path: 'traduccion-interpretacion', component: ServiciostraduccionComponent },
	{ path: 'otros-servicios', component: ServiciosotrosComponent },
	{ path: 'nosotros', component: NosotrosComponent },
	{ path: 'trabaja-con-nosotros', component: TrabajaComponent },
	{ path: 'contacto', component: ContactoComponent },
	{ path: 'en', component: HomeComponent },
	{ path: 'methodology', component: MethodologyComponent },
	{ path: 'about-us', component: AboutusComponent },
	{ path: 'work-with-us', component: WorkwithusComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'teaching-programmes', component: ServicesprogrammesComponent },
	{ path: 'translation-interpretation', component: ServicestranslationComponent },
	{ path: 'other-services', component: ServicesothersComponent },
	{ path: '**', pathMatch: 'full', redirectTo: '' }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);
