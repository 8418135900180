<navbarenglish></navbarenglish>
<div id="article" class="animated-low" style="background-image: url('/assets/img/banner-translate.png'); no-repeat center center fixed; background-attachment: fixed; background-size: 100%;">
	<h3 class="text-white" style="padding: 20px; font-size: 3em;">
		<br>
    <br>
    Translation and interpretation
    <br>
    <br>
  </h3>
</div>
<div id="article" class="animated-low">
	<h4 align="justify" class="text-secondary">
    We have a wide range of specialised translators and interpreters and a management system that enables us to deal with our clients’ requests efficiently. We also guarantee the confidentiality and protection of the data provided by our clients and all files, in strict compliance with Organic Law 15/1999 of 13 December on the Protection of Personal Data.
		<br>
		<br>
		We have established all the technical means available to clients to prevent the loss, misuse, alteration, unauthorised access and theft of the data provided by the user through any means (web, email, courier, mail, fax, etc.)
	</h4>
</div>
<div id="section" class="px-5">
	<div class="row">
		<div align="center" class="col-md-6">
			<img src="/assets/img/servicio2.png" width="70%">
		</div>
		<div align="center" class="col-md-6">
			<h3 class="text-red">IDEAL FOR:</h3>
			<table>
				<tr>
					<td valign="top">
						<img src="/assets/img/mancha-pintura.png" width="30px">
					</td>
					<td>
						<h5 style="font-size: 1.4em;" class="text-secondary">
              Balance sheets, company accounts and other financial documents.
						</h5>
					</td>
				</tr>
				<tr>
					<td valign="top">
						<img src="/assets/img/mancha-pintura.png" width="30px">
					</td>
					<td>
						<h5 style="font-size: 1.4em;" class="text-secondary">
							Correspondence and corporate documentation.
						</h5>
					</td>
				</tr>
				<tr>
					<td valign="top">
						<img src="/assets/img/mancha-pintura.png" width="30px">
					</td>
					<td>
						<h5 style="font-size: 1.4em;" class="text-secondary">
							Advertising and marketing texts.
						</h5>
					</td>
				</tr>
				<tr>
					<td valign="top">
						<img src="/assets/img/mancha-pintura.png" width="30px">
					</td>
					<td>
						<h5 style="font-size: 1.4em;" class="text-secondary">
							Translation of automotive, engineering, telecommunications, renewable energy and other technical documents.
						</h5>
					</td>
				</tr>
				<tr>
					<td valign="top">
						<img src="/assets/img/mancha-pintura.png" width="30px">
					</td>
					<td>
						<h5 style="font-size: 1.4em;" class="text-secondary">
							Translations of websites for companies.
						</h5>
					</td>
				</tr>
				<tr>
					<td valign="top">
						<img src="/assets/img/mancha-pintura.png" width="30px">
					</td>
					<td>
						<h5 style="font-size: 1.4em;" class="text-secondary">
							Software and Manuals
						</h5>
					</td>
				</tr>
			</table>
		</div>
	</div>
</div>
<div id="article" class="mt-5" style="background-image: url('/assets/img/banner-profesionalismo.png'); no-repeat center center fixed; background-attachment: fixed; background-size: 100%;">
	<h3 class="text-white" style="font-size: 2.5em;">
		We stand out for our quality, professionalism and experience
	</h3>
</div>
<div id="article" style="background-image: url('/assets/img/circle.png'); background-repeat: repeat;">
	<h2 class="font-weight-bold text-red mt-5 mb-5" style="font-size: 2.5em;">ADDED VALUES</h2>
	<div align="center" class="row mt-5">
		<div class="col-md-6 mb-4 px-5">
			<img src="/assets/img/blended.png" width="150px">
			<h5 class="text-red font-weight-bold">Communication</h5>
			<p class="px-5 text-secondary">
				We will send you a free estimate with delivery times within 24 hours. From start to finish, our professionals will be on hand for the client.
			</p>
		</div>
		<div class="col-md-6 mb-4 px-5">
			<img src="/assets/img/blended.png" width="150px">
			<h5 class="text-red font-weight-bold">Quick turnaround</h5>
			<p class="px-5 text-secondary">
				Each translator can handle an average of 2000 words per day, depending on the complexity. Teams of more than one translator can be formed for each project.
			</p>
		</div>
		<div class="col-md-6 mb-4 px-5">
			<img src="/assets/img/blended.png" width="150px">
			<h5 class="text-red font-weight-bold">Quality</h5>
			<p class="px-5 text-secondary">
				Translations are thoroughly reviewed before delivery and can be tailored to specific formats.
			</p>
		</div>
		<div class="col-md-6 mb-4 px-5">
			<img src="/assets/img/blended.png" width="150px">
			<h5 class="text-red font-weight-bold">Collaboration</h5>
			<p class="px-5 text-secondary">
				Our goal is to establish long-term relationships. Getting to know our clients allows us to improve our services day by day through feedback.
			</p>
		</div>
		<div align="center" class="col-md-4 p-4">
			<img src="/assets/img/garantia.png" width="250px">
		</div>
		<div align="center" class="col-md-8 p-4">
			<h3 class="text-secondary">
				<br>
				<br>
        We are committed to providing quality services, a prompt response and auditable
			</h3>
		</div>
	</div>
</div>
<div id="article">
	<h2 class="font-weight-bold text-red mt-5 mb-5" style="font-size: 2.5em;">CLIENTS</h2>
  <br>
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/ac-hoteles.png" width="200px" class="m-4">
              <img src="/assets/img/accenture.png" width="200px" class="m-4">
              <img src="/assets/img/accor.png" width="200px" class="m-4">
              <img src="/assets/img/adif.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/alaina.png" width="200px" class="m-4">
              <img src="/assets/img/camper.png" width="200px" class="m-4">
              <img src="/assets/img/carlton.png" width="200px" class="m-4">
              <img src="/assets/img/creditsuit.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/dunlop.png" width="200px" class="m-4">
              <img src="/assets/img/enagas.png" width="200px" class="m-4">
              <img src="/assets/img/feuvert.png" width="200px" class="m-4">
              <img src="/assets/img/gobierno-espana.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/goodyear.png" width="200px" class="m-4">
              <img src="/assets/img/groupama.png" width="200px" class="m-4">
              <img src="/assets/img/ikea.png" width="200px" class="m-4">
              <img src="/assets/img/inditex.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/kraft.png" width="200px" class="m-4">
              <img src="/assets/img/loewe.png" width="200px" class="m-4">
              <img src="/assets/img/louis.png" width="200px" class="m-4">
              <img src="/assets/img/prosegur.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/santander.png" width="200px" class="m-4">
              <img src="/assets/img/seur.png" width="200px" class="m-4">
              <img src="/assets/img/wanner-bross.png" width="200px" class="m-4">
              <img src="/assets/img/ikea.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon bg-color-red" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon bg-color-red" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
<div id="article" style="background-image: url('/assets/img/textura-formulario.png');">
  <h2 class="font-weight-bold text-white mt-5 mb-5" style="font-size: 2.5em;">CONTACT</h2>
  <div class="row">
    <div align="center" class="col-md-6 p-4">
      <div class="bg-color-red py-5 rounded-lg shadow-lg">
        <input type="text" id="name" #name="ngModel" [(ngModel)]="contact.name" class="input-style m-2" placeholder="Name" pattern="[áéíóúÁÉÍÓÚñÑa-zA-Z ]+" required>
        <small *ngIf="!name.valid && name.touched" class="text-white">
          <br>
          Error: this field is mandatory and only accepts letters and spaces
        </small>
        <br>
        <input type="text" id="phone" #phone="ngModel" [(ngModel)]="contact.phone" class="input-style m-2" placeholder="Phone" pattern="[0-9]+" required>
        <small *ngIf="!phone.valid && phone.touched" class="text-white">
          <br>
          Error: this field is mandatory and only accepts numbers
        </small>
        <br>
        <input type="email" id="email" #email="ngModel" [(ngModel)]="contact.email" class="input-style m-2" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
        <small *ngIf="!email.valid && email.touched" class="text-white">
          <br>
          Error: this field is mandatory and only accepts email
        </small>
        <br>
        <textarea id="commentary" #commentary="ngModel" [(ngModel)]="contact.commentary" class="input-style m-4" placeholder="Message" pattern="[áéíóúÁÉÍÓÚñÑa-zA-Z0-9 ]+"></textarea>
        <small *ngIf="!commentary.valid && commentary.touched" class="text-white">
          <br>
          Error: This only accepts letters and numbers
        </small>
        <br>
        <button (click)="saveContact()" class="button-style">Send</button>
      </div>
    </div>
    <div align="center" class="col-md-6">
      <br>
      <br>
      <table>
        <tr>
          <td valign="top">
            <img src="/assets/img/icon-location.png" width="40px">
          </td>
          <td>
            <h3 class="text-white">ESPAÑA</h3>
            <h5 class="text-white">
              C/ Santa Engracia n° 17 4 Dcha, 28010 Madrid, <br> España.<br>
            </h5>
          </td>
        </tr>
        <tr>
          <td>
            <img src="/assets/img/icon-phone.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              T +34 91 561 90 52 - Cel +34 691 655 780
            </h5>
          </td>
        </tr>
        <br>
        <br>
        <tr>
          <td valign="top">
            <img src="/assets/img/icon-location.png" width="40px">
          </td>
          <td>
            <h3 class="text-white">MÉXICO</h3>
            <h5 class="text-white">
              Avenida Sonora 100, Roma Norte,
              <br>
              Cuauhtémoc, C.P. 06700, CDMX<br>
            </h5>
          </td>
        </tr>
        <tr>
          <td>
            <img src="/assets/img/icon-phone.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              +52 5571587854
            </h5>
          </td>
        </tr>
        <br>
        <br>
        <tr>
          <td>
            <img src="/assets/img/icon-mailing.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              asesoria@cltraining.es
            </h5>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div id="section">
  <div id="map-container" class="z-depth-1-half map-container" style="height: 350px; width: 100%;"><iframe title="Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.038355674779!2d-3.6992024842160394!3d40.43014957936364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42288dedbf8e01%3A0xfdc7d0bae0859fe0!2sCorporate+Language+Training!5e0!3m2!1ses!2smx!4v1543971226306" frameborder="0" style="width: 100%; height: 100%;"></iframe>
  </div>
</div>
<div align="center" id="footer" class="bg-color-red text-white">
  <div class="row">
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/en']" (click)="uncheck()" class="as-none text-white">Home</a></p>
      <p><a [routerLink]="['/methodology']" (click)="uncheck()" class="as-none text-white">Methodology</a></p>
      <p><a [routerLink]="['/teaching-programmes']" (click)="uncheck()" class="as-none text-white">Teaching <br> programmes</a></p>
    </div>
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/translation-interpretation']" (click)="uncheck()" class="as-none text-white">Translation e <br> interpretation</a></p>
      <p><a [routerLink]="['/other-services']" (click)="uncheck()" class="as-none text-white">Other sevices</a></p>
      <p><a [routerLink]="['/about-us']" (click)="uncheck()" class="as-none text-white">About us</a></p>
    </div>
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/work-with-us']" (click)="uncheck()" class="as-none text-white">Work with us</a></p>
      <p><a [routerLink]="['/contact']" (click)="uncheck()" class="as-none text-white">Contact</a></p>
    </div>
  </div>
  <label class="mt-3">CL Training 2020 &reg;</label>
</div>
