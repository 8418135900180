import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { APP_ROUTING } from './app.routes';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './components/webspanish/index/index.component';
import { MetodologiaComponent } from './components/webspanish/metodologia/metodologia.component';
import { NosotrosComponent } from './components/webspanish/nosotros/nosotros.component';
import { TrabajaComponent } from './components/webspanish/trabaja/trabaja.component';
import { ContactoComponent } from './components/webspanish/contacto/contacto.component';
import { ServiciosprogramasComponent } from './components/webspanish/serviciosprogramas/serviciosprogramas.component';
import { ServiciostraduccionComponent } from './components/webspanish/serviciostraduccion/serviciostraduccion.component';
import { ServiciosotrosComponent } from './components/webspanish/serviciosotros/serviciosotros.component';
import { HomeComponent } from './components/webenglish/home/home.component';
import { NavbarspanishComponent } from './components/includes/navbarspanish/navbarspanish.component';
import { NavbarenglishComponent } from './components/includes/navbarenglish/navbarenglish.component';
import { MethodologyComponent } from './components/webenglish/methodology/methodology.component';
import { AboutusComponent } from './components/webenglish/aboutus/aboutus.component';
import { WorkwithusComponent } from './components/webenglish/workwithus/workwithus.component';
import { ContactComponent } from './components/webenglish/contact/contact.component';
import { ServicesprogrammesComponent } from './components/webenglish/servicesprogrammes/servicesprogrammes.component';
import { ServicestranslationComponent } from './components/webenglish/servicestranslation/servicestranslation.component';
import { ServicesothersComponent } from './components/webenglish/servicesothers/servicesothers.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    MetodologiaComponent,
    NosotrosComponent,
    TrabajaComponent,
    ContactoComponent,
    ServiciosprogramasComponent,
    ServiciostraduccionComponent,
    ServiciosotrosComponent,
    HomeComponent,
    NavbarspanishComponent,
    NavbarenglishComponent,
    MethodologyComponent,
    AboutusComponent,
    WorkwithusComponent,
    ContactComponent,
    ServicesprogrammesComponent,
    ServicestranslationComponent,
    ServicesothersComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    APP_ROUTING
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
