import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Contact } from '../interfaces/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  API_ENDPOINT = 'https://www.beta.grupoaz.com/api';

  constructor( private httpClient: HttpClient ) { }

  save(contact: Contact){
  	const headers = new HttpHeaders({'Content-Type': 'application/json'});
  	return this.httpClient.post(this.API_ENDPOINT+'/guardar-contacto',contact,{headers: headers});
  }
}
