import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'navbarenglish',
  templateUrl: './navbarenglish.component.html'
})
export class NavbarenglishComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  uncheck(){
  	const menuCheck = document.getElementById("menu-bar") as HTMLInputElement;
	  menuCheck.checked = false;

    window.scrollTo( 0, 0 );
  }
}
