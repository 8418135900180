<navbarspanish></navbarspanish>
<div id="section" class="animated-low gone">
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" style="margin-top: -50px;">
    <ol class="carousel-indicators">
    	<li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        	<img class="d-block w-100" src="/assets/img/banner-1.png" alt="First slide">
        	<div class="carousel-caption d-none d-md-block">
  	  		<h2 class="display-4">Todos los idiomas con cobertura nacional e internacional</h2>
  	    	<!-- <p>...</p> -->
  	  	</div>
      </div>
      <div class="carousel-item">
        	<img class="d-block w-100" src="/assets/img/banner-2.png" alt="Second slide">
      	<div class="carousel-caption d-none d-md-block">
  	    	<h5 class="display-4">Más de 200 formadores de alto nivel y 4.500 horas mensuales</h5>
          <!-- <p>...</p> -->
  	  	</div>
      </div>
      <div class="carousel-item">
        	<img class="d-block w-100" src="/assets/img/banner-3.png" alt="Third slide">
      	<div class="carousel-caption d-none d-md-block">
  	    	<h5 class="display-4">Más de 30 años brindando soluciones lingüísticas a empresas</h5>
  	    	<!-- <p>...</p> -->
  	  	</div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
<div id="article" class="animated-fast">
  <div class="row">
    <div class="col-md-6">
      <h5 align="justify" class="text-secondary">
        <br>
        <br>
        Ofrecemos soluciones lingüísticas de calidad para empresas gracias a una metodología integral que combina herramientas online (plataforma CL) con sesiones presenciales y telefónicas/skype. Toda nuestra oferta formativa cumple con los requisitos exigidos por FUNDAE
      </h5>
    </div>
    <div class="col-md-6">
      <img src="/assets/img/fundacion-estatal.png" width="250px">
    </div>
  </div>
  <br>
  <br>
  <div class="card-deck mt-5">
    <div class="card" style="background-image: url('/assets/img/programas-de-ensenanza.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
      <div class="bg-color-red">
        <h5 class="py-2 text-white">Programas de enseñanza</h5>
      </div>
      <div class="card-body">
        <p class="card-text text-white">Aprende cualquier idioma con la metodología que más se adapte a tu día a día: blended, online, presencial, curso crash, telefónico, etc.</p>
        <br>
      </div>
      <small align="right" class="m-3 text-white"><a [routerLink]="['/programas-de-ensenanza']" (click)="uncheck()" class="text-white as-none">MÁS ></a></small>
    </div>
    <div class="card" style="background-image: url('/assets/img/traduccioneinterpretacion.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
      <div class="bg-color-red">
          <h5 class="py-2 text-white">Traducción e interpretación</h5>
      </div>
      <div class="card-body">
        <p class="card-text text-white">Traducciones e interpretaciones generales, comerciales, técnicas, legales, financieras, literarias, publicitarias y juradas.</p>
        <br>
      </div>
      <small align="right" class="m-3 text-white"><a [routerLink]="['/traduccion-interpretacion']" (click)="uncheck()" class="text-white as-none">MÁS ></a></small>
    </div>
    <div class="card" style="background-image: url('/assets/img/otros-servicios.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
      <div class="bg-color-red">
        <h5 class="py-2 text-white">Otros servicios</h5>
      </div>
      <div class="card-body">
        <p class="card-text text-white">Auditoría lingüística, apoyo en procesos de selección, inmersiones, seminarios, workshops/talleres, etc.</p>
        <br>
      </div>
      <small align="right" class="m-3 text-white"><a [routerLink]="['/otros-servicios']" (click)="uncheck()" class="text-white as-none">MÁS ></a></small>
    </div>
  </div>
</div>
<div id="article" style="background-image: url('/assets/img/circle.png'); background-repeat: repeat;">
  <h2 class="font-weight-bold text-red mt-5 mb-5" style="font-size: 2.5em;">NUESTRO ADN</h2>
  <div class="row px-5">
    <div class="col-md-6 px-lg-5 mb-4">
      <img src="/assets/img/icono-calidad.png" width="100px">
      <h5 class="font-weight-bold mt-3 mb-3 text-secondary">Calidad</h5>
      <p align="center" class="px-5 text-secondary">
        Compromiso de satisfacción con cada cliente, sin importar el tamaño, con exhaustivos controles de desempeño.
      </p>
    </div>
    <div class="col-md-6 px-lg-5 mb-4">
      <img src="/assets/img/icono-flexibilidad.png" width="100px">
      <h5 class="font-weight-bold mt-3 mb-3 text-secondary">Flexibilidad</h5>
      <p align="center" class="px-5 text-secondary">
        Gracias a nuestra experiencia y capacidad, nos adaptamos a las necesidades de nuestros clientes.
      </p>
    </div>
    <div class="col-md-6 px-lg-5 mb-4">
      <img src="/assets/img/icono-cercania.png" width="100px">
      <h5 class="font-weight-bold mt-3 mb-3 text-secondary">Cercanía</h5>
      <p align="center" class="px-5 text-secondary">
        Nos involucramos en cada proyecto para asegurar el éxito, con posibilidad de inplant en instalaciones del Cliente.
      </p>
    </div>
    <div class="col-md-6 px-lg-5 mb-4">
      <img src="/assets/img/icono-especializacion.png" width="100px">
      <h5 class="font-weight-bold mt-3 mb-3 text-secondary">Especialización</h5>
      <p align="center" class="px-5 text-secondary">
        Realizamos programas eficaces, centrados en lo que realmente necesita el cliente.
      </p>
    </div>
  </div>
</div>
<div id="article">
  <h2 class="font-weight-bold text-red mt-5" style="font-size: 2.5em;">CLIENTES</h2>
  <br>
  <br>
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/ac-hoteles.png" width="200px" class="m-4">
              <img src="/assets/img/accenture.png" width="200px" class="m-4">
              <img src="/assets/img/accor.png" width="200px" class="m-4">
              <img src="/assets/img/adif.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/alaina.png" width="200px" class="m-4">
              <img src="/assets/img/camper.png" width="200px" class="m-4">
              <img src="/assets/img/carlton.png" width="200px" class="m-4">
              <img src="/assets/img/creditsuit.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/dunlop.png" width="200px" class="m-4">
              <img src="/assets/img/enagas.png" width="200px" class="m-4">
              <img src="/assets/img/feuvert.png" width="200px" class="m-4">
              <img src="/assets/img/gobierno-espana.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/goodyear.png" width="200px" class="m-4">
              <img src="/assets/img/groupama.png" width="200px" class="m-4">
              <img src="/assets/img/ikea.png" width="200px" class="m-4">
              <img src="/assets/img/inditex.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/kraft.png" width="200px" class="m-4">
              <img src="/assets/img/loewe.png" width="200px" class="m-4">
              <img src="/assets/img/louis.png" width="200px" class="m-4">
              <img src="/assets/img/prosegur.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/santander.png" width="200px" class="m-4">
              <img src="/assets/img/seur.png" width="200px" class="m-4">
              <img src="/assets/img/wanner-bross.png" width="200px" class="m-4">
              <img src="/assets/img/ikea.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon bg-color-red" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon bg-color-red" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
<div id="article" style="background-image: url('/assets/img/textura-formulario.png');">
  <h2 class="font-weight-bold text-white mt-4 mb-5" style="font-size: 2.5em;">CONTACTO</h2>
  <div class="row">
    <div align="center" class="col-md-6 p-4">
      <div class="bg-color-red py-5 rounded-lg shadow-lg">
        <input type="text" id="name" #name="ngModel" [(ngModel)]="contact.name" class="input-style m-2" placeholder="Nombre" pattern="[áéíóúÁÉÍÓÚñÑa-zA-Z ]+" required>
        <small *ngIf="!name.valid && name.touched" class="text-white">
          <br>
          Error: este campo es obligatorio y sólo acepta letras y espacios
        </small>
        <br>
        <input type="text" id="phone" #phone="ngModel" [(ngModel)]="contact.phone" class="input-style m-2" placeholder="Teléfono" pattern="[0-9]+" required>
        <small *ngIf="!phone.valid && phone.touched" class="text-white">
          <br>
          Error: este campo es obligatorio y sólo acepta números
        </small>
        <br>
        <input type="email" id="email" #email="ngModel" [(ngModel)]="contact.email" class="input-style m-2" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
        <small *ngIf="!email.valid && email.touched" class="text-white">
          <br>
          Error: este campo es obligatorio y sólo acepta email
        </small>
        <br>
        <textarea id="commentary" #commentary="ngModel" [(ngModel)]="contact.commentary" class="input-style m-4" placeholder="Mensaje" pattern="[áéíóúÁÉÍÓÚñÑa-zA-Z0-9 ]+"></textarea>
        <small *ngIf="!commentary.valid && commentary.touched" class="text-white">
          <br>
          Error: Este sólo acepta letras y números
        </small>
        <br>
        <button (click)="saveContact()" class="button-style">Enviar</button>
      </div>
    </div>
    <div align="center" class="col-md-6">
      <br>
      <table>
        <tr>
          <td valign="top">
            <img src="/assets/img/icon-location.png" width="40px">
          </td>
          <td>
            <h3 class="text-white">ESPAÑA</h3>
            <h5 class="text-white">
              C/ Santa Engracia n° 17 4 Dcha, 28010 Madrid, <br> España.<br>
            </h5>
          </td>
        </tr>
        <tr>
          <td>
            <img src="/assets/img/icon-phone.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              T +34 91 561 90 52 - Móv. +34 691 655 780
            </h5>
          </td>
        </tr>
        <br>
        <br>
        <tr>
          <td valign="top">
            <img src="/assets/img/icon-location.png" width="40px">
          </td>
          <td>
            <h3 class="text-white">MÉXICO</h3>
            <h5 class="text-white">
              Avenida Sonora 100, Roma Norte,
              <br>
              Cuauhtémoc, C.P. 06700, CDMX<br>
            </h5>
          </td>
        </tr>
        <tr>
          <td>
            <img src="/assets/img/icon-phone.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              +52 5571587854
            </h5>
          </td>
        </tr>
        <br>
        <br>
        <tr>
          <td>
            <img src="/assets/img/icon-mailing.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              asesoria@cltraining.es
            </h5>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div id="section">
  <div id="map-container" class="z-depth-1-half map-container" style="height: 350px; width: 100%;"><iframe title="Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.038355674779!2d-3.6992024842160394!3d40.43014957936364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42288dedbf8e01%3A0xfdc7d0bae0859fe0!2sCorporate+Language+Training!5e0!3m2!1ses!2smx!4v1543971226306" frameborder="0" style="width: 100%; height: 100%;"></iframe>
  </div>
</div>
<div align="center" id="footer" class="bg-color-red text-white">
  <div class="row">
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/']" (click)="uncheck()" class="as-none text-white">Inicio</a></p>
      <p><a [routerLink]="['/metodologia']" (click)="uncheck()" class="as-none text-white">Metodología</a></p>
      <p><a [routerLink]="['/programas-de-ensenanza']" (click)="uncheck()" class="as-none text-white">Programas <br> de enseñanza</a></p>
    </div>
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/traduccion-interpretacion']" (click)="uncheck()" class="as-none text-white">Traducción e <br> interpretación</a></p>
      <p><a [routerLink]="['/otros-servicios']" (click)="uncheck()" class="as-none text-white">Otros servicios</a></p>
      <p><a [routerLink]="['/nosotros']" (click)="uncheck()" class="as-none text-white">Nosotros</a></p>
    </div>
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/trabaja-con-nosotros']" (click)="uncheck()" class="as-none text-white">Trabaja con <br> nosotros</a></p>
      <p><a [routerLink]="['/contacto']" (click)="uncheck()" class="as-none text-white">Contacto</a></p>
    </div>
  </div>
  <label class="mt-3">CL Training 2020 &reg;</label>
</div>
