<style type="text/css">
/* ------------------------------------------ */
/* Navbar */
/* ------------------------------------------ */
@import url(https://weloveiconfonts.com/api/?family=fontawesome|typicons);
[class*="fontawesome-"]:before{
  font-family: 'FontAwesome', sans-serif; }

*{
    margin: 0;
    padding: 0;
    font-family: 'Century Gothic', sans-serif; }

#navicon{
    float: right;
    margin-right: 15px; }

header{
    width: 100%;
    height: 90px;
    background: #fff;
    color: #000;
    position: fixed;
    z-index: 100;
    border-bottom: 1px #fff solid;
    border-top: 1px #fff solid;
    box-shadow: 0px 0.2px 0.6em gray; }

#menu-bar{
    display: none; }

header .logo{
    float: left;
    margin-top: 6px;
    margin-left: 1%; }

header label{
    float: right;
    font-size: 30px;
    cursor: pointer;
    margin-top: 21px;
    margin-left: 10px;
    margin-right: 15px; }

.menu{
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    transition: all 0.6s;
    transform: translateX(-100%); }

.menu a{
    display: block;
    color: #5d5d5d;
    font-weight: bold;
    height: auto;
    text-decoration: none;
    padding: 15px;
    border-left: 2px #bd2b30 solid;
    transition: all 0.5s; }

.menu a:hover{
    background: #bd2b30;
    color: #fff; }

#menu-bar:checked ~ .menu{
    margin-top: 10px;
    transform: translateX(0%); }

@media (min-width:1100px){
    .menu{
        position: static;
        width: auto;
        height: auto;
        transform: translateX(0%);
        float: right;
        display: flex; }

    header label{
        display: none; }
}

@media screen and (max-width: 1100px){
    a{
        border-bottom: 3px #c6c6c6 solid; }
}
</style>
<header>
    <div class="contenedor">
        <a [routerLink]="['/']" (click)="uncheck()">
            <img src="/assets/img/cltraining.png" width="70px" class="logo ml-4">
        </a>
        <div id="navbar">
            <input type="checkbox" id="menu-bar">
            <label class="fontawesome-align-justify" for="menu-bar"></label>
            <nav class="menu pt-3">
                <a [routerLink]="['/']" (click)="uncheck()" class="px-3">Inicio</a>
                <a [routerLink]="['/metodologia']" (click)="uncheck()" class="px-3">Metodología</a>

                	<a class="dropdown dropdown-toggle px-3" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                		Servicios
						<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
							<a class="dropdown-item px-3" [routerLink]="['/programas-de-ensenanza']" (click)="uncheck()">Programas de enseñanza</a>
						    <a class="dropdown-item px-3" [routerLink]="['/traduccion-interpretacion']" (click)="uncheck()">Traducción e interpretación</a>
						    <a class="dropdown-item px-3" [routerLink]="['/otros-servicios']" (click)="uncheck()">Otros servicios</a>
						</div>
					</a>

                <a [routerLink]="['/nosotros']" (click)="uncheck()" class="px-4">Nosotros</a>
                <a [routerLink]="['/trabaja-con-nosotros']" (click)="uncheck()" class="px-4">Trabaja con nosotros</a>
                <a [routerLink]="['/contacto']" (click)="uncheck()" class="px-4">Contacto</a>
            </nav>
        </div>
        <div id="navicon" style="padding-top: 20px;">
            <a [routerLink]="['/']" class="as-none mr-2" (click)="uncheck()">
                <img src="/assets/img/espana.png" class="icon" width="55px">
            </a>
            <a [routerLink]="['/en']" class="as-none" (click)="uncheck()">
                <img src="/assets/img/uk.png" class="icon border rounded" width="47px" height="32px">
            </a>
        </div>
    </div>
</header>
