import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Contact } from '../../../interfaces/contact';
import { ContactsService } from '../../../services/contacts.service';

@Component({
  selector: 'app-trabaja',
  templateUrl: './trabaja.component.html'
})
export class TrabajaComponent implements OnInit {

  contact: Contact = {
  name: null,
  phone: null,
  email: null,
  reason: 'trabaja con nosotros',
  commentary: null,
  company: 'cltraining'
  };

  contacts: Contact[];

  constructor( private contactService: ContactsService, private httpClient: HttpClient ) { }

  ngOnInit(): void {
  }

  saveContact(){
    this.contactService.save(this.contact).subscribe(
      (data) => {
      alert('Tus datos se han enviado correctamente');
      document.location.href="/";
      window.scrollTo( 0, 0 );
    },
      (error) => {
      alert('Ocurrió un error, intenta de nuevo');
      console.log(error);
      }
    );
  }

  uncheck(){
    const menuCheck = document.getElementById("menu-bar") as HTMLInputElement;
    menuCheck.checked = false;

    window.scrollTo( 0, 0 );
  }

}
