<navbarenglish></navbarenglish>
<div id="article" class="animated-low" style="background-image: url('/assets/img/banner-nosotros.png');  no-repeat center center fixed; background-attachment: fixed; background-size: 100%;">
  <h3 class="text-white" style="padding: 20px; font-size: 3em;">
    <br>
    <br>
    About us
    <br>
    <br>
  </h3>
</div>
<div id="article" class="animated-low">
  <img src="/assets/img/cltraining.png" width="150px">
  <br>
  <br>
  <br>
 	<h3 align="justify" class="text-secondary">
		Part of the AZ Group, Corporate Language Training was founded in 1989 as a pioneering consultancy in Spain in language teaching for the business world at a time when no one in the sector was focusing on specialisation as the key to differentiation. Our mission: to provide the best corporate language solutions with a real return on investment for the client.
	</h3>
	<h2 class="font-weight-bold text-red mt-5 mb-5" style="font-size: 2.5em;">THE FIGURES SPEAK FOR THEMSELVES</h2>
	<div class="row">
		<div class="col-md-4 p-2 text-white">
			<div class="py-5" style="background-image: url('/assets/img/todos-los-idiomas.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
				<label style="width: 200px; background-image: url('/assets/img/mancha.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; font-size: 3em;">All</label>
				<br>
				<p style="font-size: 1.6em;">languages</p>
			</div>
		</div>
		<div class="col-md-4 p-2 text-white">
			<div class="py-5" style="background-image: url('/assets/img/30-anios.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
				<label style="width: 200px; background-image: url('/assets/img/mancha.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; font-size: 3em;">30 years</label>
				<br>
				<p style="font-size: 1.6em;">of experience</p>
			</div>
		</div>
		<div class="col-md-4 p-2 text-white">
			<div class="py-5" style="background-image: url('/assets/img/198-clientes.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
				<label style="width: 200px; background-image: url('/assets/img/mancha.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; font-size: 3em;">198</label>
				<br>
				<p  style="font-size: 1.6em;">major clients</p>
			</div>
		</div>
		<div class="col-md-4 p-2 text-white">
			<div class="py-5" style="background-image: url('/assets/img/cobertura.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
				<label style="width: 200px; background-image: url('/assets/img/mancha.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; font-size: 3em;">Coverage</label>
				<br>
				<p style="font-size: 1.6em;">Domestic and international</p>
			</div>
		</div>
		<div class="col-md-4 p-2 text-white">
			<div class="py-5" style="background-image: url('/assets/img/230-formadores.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
				<label style="width: 200px; background-image: url('/assets/img/mancha.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; font-size: 3em;">230</label>
				<br>
				<p style="font-size: 1.6em;">teachers, translators</p>
			</div>
		</div>
		<div class="col-md-4 p-2 text-white">
			<div class="py-5" style="background-image: url('/assets/img/450-formadores.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
				<label style="width: 200px; background-image: url('/assets/img/mancha.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; font-size: 3em;">>4.500</label>
				<br>
				<p style="font-size: 1.6em;">>class hours per month</p>
			</div>
		</div>
	</div>
  <br>
  <br>
  <div id="article" style="background-image: url('/assets/img/circle.png'); background-repeat: repeat;">
	<h2 class="font-weight-bold text-red mt-5 mb-5" style="font-size: 2.5em;">WHY US?</h2>
	<div class="row">
		<div align="center" class="col-md-4 p-4">
			<img src="/assets/img/especializacion.png" width="120px">
			<h2 class="text-secondary">Specialisation</h2>
			<h4 class="text-secondary">
				Adapting to
				<br>
				customer needs
			</h4>
		</div>
		<div align="center" class="col-md-4 p-4">
			<img src="/assets/img/personas.png" width="120px">
			<h2 class="text-secondary">People</h2>
			<h4 class="text-secondary">
				Approachable teachers
				<br>
				and corporate staff
			</h4>
		</div>
		<div align="center" class="col-md-4 p-4">
			<img src="/assets/img/eficacia.png" width="120px">
			<h2 class="text-secondary">Efficiency</h2>
			<h4 class="text-secondary">
				Thanks to our
				<br>
				innovative methodology
			</h4>
		</div>
		<div align="center" class="col-md-4 p-4">
			<img src="/assets/img/soluciones.png" width="120px">
			<h2 class="text-secondary">Solutions</h2>
			<h4 class="text-secondary">
				So that all you need to
				<br>
				worry about is learning
			</h4>
		</div>
		<div align="center" class="col-md-4 p-4">
			<img src="/assets/img/futuro.png" width="120px">
			<h2 class="text-secondary">Improvement</h2>
			<h4 class="text-secondary">
				In-depth quality
				<br>
				controls and follow-up
			</h4>
		</div>
		<div align="center" class="col-md-4 p-4">
			<img src="/assets/img/mejora.png" width="120px">
			<h2 class="text-secondary">Future</h2>
			<h4 class="text-secondary">
				We engage to work
				<br>
				for the long term
			</h4>
		</div>
		<div align="center" class="col-md-4 p-4">
			<img src="/assets/img/garantia.png" width="250px">
		</div>
		<div align="center" class="col-md-8 p-4">
			<h3 class="text-secondary">
				<br>
				<br>
				Our commitment is to provide quality services, prompt attention and auditable results.
			</h3>
		</div>
	</div>
  </div>
	<h2 class="font-weight-bold text-red mt-5 mb-5" style="font-size: 2.5em;">CLIENTS</h2>
  <br>
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/ac-hoteles.png" width="200px" class="m-4">
              <img src="/assets/img/accenture.png" width="200px" class="m-4">
              <img src="/assets/img/accor.png" width="200px" class="m-4">
              <img src="/assets/img/adif.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/alaina.png" width="200px" class="m-4">
              <img src="/assets/img/camper.png" width="200px" class="m-4">
              <img src="/assets/img/carlton.png" width="200px" class="m-4">
              <img src="/assets/img/creditsuit.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/dunlop.png" width="200px" class="m-4">
              <img src="/assets/img/enagas.png" width="200px" class="m-4">
              <img src="/assets/img/feuvert.png" width="200px" class="m-4">
              <img src="/assets/img/gobierno-espana.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/goodyear.png" width="200px" class="m-4">
              <img src="/assets/img/groupama.png" width="200px" class="m-4">
              <img src="/assets/img/ikea.png" width="200px" class="m-4">
              <img src="/assets/img/inditex.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/kraft.png" width="200px" class="m-4">
              <img src="/assets/img/loewe.png" width="200px" class="m-4">
              <img src="/assets/img/louis.png" width="200px" class="m-4">
              <img src="/assets/img/prosegur.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/santander.png" width="200px" class="m-4">
              <img src="/assets/img/seur.png" width="200px" class="m-4">
              <img src="/assets/img/wanner-bross.png" width="200px" class="m-4">
              <img src="/assets/img/ikea.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon bg-color-red" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon bg-color-red" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
<div id="article" style="background-image: url('/assets/img/textura-formulario.png');">
  <h2 class="font-weight-bold text-white mt-5 mb-5" style="font-size: 2.5em;">CONTACT</h2>
  <div class="row">
    <div align="center" class="col-md-6 p-4">
      <div class="bg-color-red py-5 rounded-lg shadow-lg">
        <input type="text" id="name" #name="ngModel" [(ngModel)]="contact.name" class="input-style m-2" placeholder="Name" pattern="[áéíóúÁÉÍÓÚñÑa-zA-Z ]+" required>
        <small *ngIf="!name.valid && name.touched" class="text-white">
          <br>
          Error: this field is mandatory and only accepts letters and spaces
        </small>
        <br>
        <input type="text" id="phone" #phone="ngModel" [(ngModel)]="contact.phone" class="input-style m-2" placeholder="Phone" pattern="[0-9]+" required>
        <small *ngIf="!phone.valid && phone.touched" class="text-white">
          <br>
          Error: this field is mandatory and only accepts numbers
        </small>
        <br>
        <input type="email" id="email" #email="ngModel" [(ngModel)]="contact.email" class="input-style m-2" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
        <small *ngIf="!email.valid && email.touched" class="text-white">
          <br>
          Error: this field is mandatory and only accepts email
        </small>
        <br>
        <textarea id="commentary" #commentary="ngModel" [(ngModel)]="contact.commentary" class="input-style m-4" placeholder="Message" pattern="[áéíóúÁÉÍÓÚñÑa-zA-Z0-9 ]+"></textarea>
        <small *ngIf="!commentary.valid && commentary.touched" class="text-white">
          <br>
          Error: This only accepts letters and numbers
        </small>
        <br>
        <button (click)="saveContact()" class="button-style">Send</button>
      </div>
    </div>
    <div align="center" class="col-md-6">
      <br>
      <br>
      <table>
        <tr>
          <td valign="top">
            <img src="/assets/img/icon-location.png" width="40px">
          </td>
          <td>
            <h3 class="text-white">ESPAÑA</h3>
            <h5 class="text-white">
              C/ Santa Engracia n° 17 4 Dcha, 28010 Madrid, <br> España.<br>
            </h5>
          </td>
        </tr>
        <tr>
          <td>
            <img src="/assets/img/icon-phone.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              T +34 91 561 90 52 - Cel +34 691 655 780
            </h5>
          </td>
        </tr>
        <br>
        <br>
        <tr>
          <td valign="top">
            <img src="/assets/img/icon-location.png" width="40px">
          </td>
          <td>
            <h3 class="text-white">MÉXICO</h3>
            <h5 class="text-white">
              Avenida Sonora 100, Roma Norte,
              <br>
              Cuauhtémoc, C.P. 06700, CDMX<br>
            </h5>
          </td>
        </tr>
        <tr>
          <td>
            <img src="/assets/img/icon-phone.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              +52 5571587854
            </h5>
          </td>
        </tr>
        <br>
        <br>
        <tr>
          <td>
            <img src="/assets/img/icon-mailing.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              asesoria@cltraining.es
            </h5>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div id="section">
  <div id="map-container" class="z-depth-1-half map-container" style="height: 350px; width: 100%;"><iframe title="Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.038355674779!2d-3.6992024842160394!3d40.43014957936364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42288dedbf8e01%3A0xfdc7d0bae0859fe0!2sCorporate+Language+Training!5e0!3m2!1ses!2smx!4v1543971226306" frameborder="0" style="width: 100%; height: 100%;"></iframe>
  </div>
</div>
<div align="center" id="footer" class="bg-color-red text-white">
  <div class="row">
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/en']" (click)="uncheck()" class="as-none text-white">Home</a></p>
      <p><a [routerLink]="['/methodology']" (click)="uncheck()" class="as-none text-white">Methodology</a></p>
      <p><a [routerLink]="['/teaching-programmes']" (click)="uncheck()" class="as-none text-white">Teaching <br> programmes</a></p>
    </div>
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/translation-interpretation']" (click)="uncheck()" class="as-none text-white">Translation e <br> interpretation</a></p>
      <p><a [routerLink]="['/other-services']" (click)="uncheck()" class="as-none text-white">Other sevices</a></p>
      <p><a [routerLink]="['/about-us']" (click)="uncheck()" class="as-none text-white">About us</a></p>
    </div>
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/work-with-us']" (click)="uncheck()" class="as-none text-white">Work with us</a></p>
      <p><a [routerLink]="['/contact']" (click)="uncheck()" class="as-none text-white">Contact</a></p>
    </div>
  </div>
  <label class="mt-3">CL Training 2020 &reg;</label>
</div>
