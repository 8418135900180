import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Contact } from '../../../interfaces/contact';
import { ContactsService } from '../../../services/contacts.service';

@Component({
  selector: 'app-servicesprogrammes',
  templateUrl: './servicesprogrammes.component.html'
})
export class ServicesprogrammesComponent implements OnInit {

  contact: Contact = {
  name: null,
  phone: null,
  email: null,
  reason: 'contact',
  commentary: null,
  company: 'cltraining'
  };

  contacts: Contact[];

  constructor( private contactService: ContactsService, private httpClient: HttpClient ) { }

  ngOnInit(): void {
  }

  saveContact(){
    this.contactService.save(this.contact).subscribe(
      (data) => {
      alert('Your data has been sent correctly');
      document.location.href="/en";
      window.scrollTo( 0, 0 );
    },
      (error) => {
      alert('An error occurred, try again');
      }
    );
  }

  uncheck(){
    const menuCheck = document.getElementById("menu-bar") as HTMLInputElement;
    menuCheck.checked = false;

    window.scrollTo( 0, 0 );
  }

}
