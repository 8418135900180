<navbarspanish></navbarspanish>
<div id="article" class="animated-low" style="background-image: url('/assets/img/banner-programasensenanza.png'); no-repeat center center fixed; background-attachment: fixed; background-size: 100%;">
	<h3 class="text-white" style="padding: 20px; font-size: 3em;">
		<br>
    <br>
    Programas de enseñanza
    <br>
    <br>
	</h3>
</div>
<div id="article" class="animated-low">
	<h3 align="center" class="text-secondary">
		Nuestros programas están personalizados con base en los objetivos, entorno y necesidades de nuestros clientes. No sólo buscamos la manera más eficiente de aprender un idioma, también nos centramos en la realidad o sector del alumno para que aprenda a desenvolverse en situaciones concretas del día a día.
	</h3>
</div>
<div id="section" style="background-color: #dce7ed;" class="px-5">
	<div class="row">
		<div align="center" class="col-md-6">
			<img src="/assets/img/ideal-para.png" width="100%">
		</div>
		<div align="center" class="col-md-6">
			<br>
			<br>
      <br>
			<h3 class="text-red">IDEAL PARA:</h3>
			<table>
				<tr>
					<td valign="top">
						<img src="/assets/img/mancha-pintura.png" width="30px">
					</td>
					<td>
						<h5 style="font-size: 1.4em;">
							Personal o departamento con contacto internacional
						</h5>
					</td>
				</tr>
				<tr>
					<td valign="top">
						<img src="/assets/img/mancha-pintura.png" width="30px">
					</td>
					<td>
						<h5 style="font-size: 1.4em;">
							Profesionales con poca disponibilidad de horario
						</h5>
					</td>
				</tr>
				<tr>
					<td valign="top">
						<img src="/assets/img/mancha-pintura.png" width="30px">
					</td>
					<td>
						<h5 style="font-size: 1.4em;">
							Profesionales con conocimientos básicos y necesidad de mejora
						</h5>
					</td>
				</tr>
				<tr>
					<td valign="top">
						<img src="/assets/img/mancha-pintura.png" width="30px">
					</td>
					<td>
						<h5 style="font-size: 1.4em;">
							Principios absolutos
						</h5>
					</td>
				</tr>
				<tr>
					<td valign="top">
						<img src="/assets/img/mancha-pintura.png" width="30px">
					</td>
					<td>
						<h5 style="font-size: 1.4em;">
							Altos cargos
						</h5>
					</td>
				</tr>
				<tr>
					<td valign="top">
						<img src="/assets/img/mancha-pintura.png" width="30px">
					</td>
					<td>
						<h5 style="font-size: 1.4em;">
							Personal o departamento con contacto internacional
						</h5>
					</td>
				</tr>
			</table>
		</div>
	</div>
</div>
<div id="section">
	<h2 class="font-weight-bold text-red mt-5 mb-5" style="font-size: 2.5em;">MODALIDADES</h2>
	<br>
	<div class="row" style="width: 100%;">
		<div class="col-md-12 pt-2 pb-2 mb-3">
			<div class="p-4" style="background-image: url('/assets/img/modalidadblended.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
				<h2 class="display-5 font-weight-bold text-white mt-5">Blended</h2>
				<h3 class="text-white">
					Programas integrales que combinan clases presenciales, plataforma online y clases telefónicas/skype para un aprendizaje 360 grados.
				</h3>
        <button class="button-style mt-3"><a href="/assets/files/blended.pdf" target="_blank" class="as-none">PDF</a></button>
			</div>
		</div>
		<div class="col-md-4 pt-1 pb-1">
			<div class="p-5" style="background-image: url('/assets/img/lenguaje.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
				<h2 class="display-5 font-weight-bold text-white mt-5">Language line</h2>
				<h5 class="text-white">
					Ideal para profesionales que no dispongan
					<br>
					de horarios preestablecidos.
          <br>
				</h5>
        <button class="button-style mt-3"><a href="/assets/files/language-line.pdf" target="_blank" class="as-none">PDF</a></button>
			</div>
		</div>
		<div class="col-md-4 pt-1 pb-1">
			<div class="p-5" style="background-image: url('/assets/img/modalidadpresencial.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
				<h2 class="display-5 font-weight-bold text-white mt-5">Presencial</h2>
				<h5 class="text-white">
					Cursos convencionales, crash (intensivos),
					<br>
					grupales o individuales.
				</h5>
        <button class="button-style mt-3"><a href="/assets/files/programas-presenciales.pdf" target="_blank" class="as-none">PDF</a></button>
			</div>
		</div>
		<div class="col-md-4 pt-1 pb-1">
			<div class="py-5" style="background-image: url('/assets/img/modalidadonline.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
				<h2 class="display-5 font-weight-bold text-white mt-5">Online<br></h2>
				<h5 class="text-white">
					A través de la Plataforma CL, los alumnos tendrán
					<br>
					acceso a cursos y contenido online.
				</h5>
        <button class="button-style mt-3"><a href="/assets/files/plataforma-cl.pdf" target="_blank" class="as-none">PDF</a></button>
			</div>
		</div>
		<div align="center" class="col-md-4 p-4">
			<img src="/assets/img/garantia.png" width="250px">
		</div>
		<div align="center" class="col-md-8 p-4">
			<h3 class="text-secondary">
				<br>
				<br>
				Nuestro compromiso es brindar servicios de calidad, una atención rápida y resultados auditables.
			</h3>
		</div>
	</div>
	<h2 class="font-weight-bold text-red mt-5 mb-5" style="font-size: 2.5em;">CLIENTES</h2>
  <br>
  <br>
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/ac-hoteles.png" width="200px" class="m-4">
              <img src="/assets/img/accenture.png" width="200px" class="m-4">
              <img src="/assets/img/accor.png" width="200px" class="m-4">
              <img src="/assets/img/adif.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/alaina.png" width="200px" class="m-4">
              <img src="/assets/img/camper.png" width="200px" class="m-4">
              <img src="/assets/img/carlton.png" width="200px" class="m-4">
              <img src="/assets/img/creditsuit.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/dunlop.png" width="200px" class="m-4">
              <img src="/assets/img/enagas.png" width="200px" class="m-4">
              <img src="/assets/img/feuvert.png" width="200px" class="m-4">
              <img src="/assets/img/gobierno-espana.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/goodyear.png" width="200px" class="m-4">
              <img src="/assets/img/groupama.png" width="200px" class="m-4">
              <img src="/assets/img/ikea.png" width="200px" class="m-4">
              <img src="/assets/img/inditex.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/kraft.png" width="200px" class="m-4">
              <img src="/assets/img/loewe.png" width="200px" class="m-4">
              <img src="/assets/img/louis.png" width="200px" class="m-4">
              <img src="/assets/img/prosegur.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/santander.png" width="200px" class="m-4">
              <img src="/assets/img/seur.png" width="200px" class="m-4">
              <img src="/assets/img/wanner-bross.png" width="200px" class="m-4">
              <img src="/assets/img/ikea.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon bg-color-red" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon bg-color-red" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
<div id="article" style="background-image: url('/assets/img/textura-formulario.png');">
  <h2 class="font-weight-bold text-white mt-5 mb-5" style="font-size: 2.5em;">CONTACTO</h2>
  <div class="row">
    <div align="center" class="col-md-6 p-4">
      <div class="bg-color-red py-5 rounded-lg shadow-lg">
        <input type="text" id="name" #name="ngModel" [(ngModel)]="contact.name" class="input-style m-2" placeholder="Nombre" pattern="[áéíóúÁÉÍÓÚñÑa-zA-Z ]+" required>
        <small *ngIf="!name.valid && name.touched" class="text-white">
          <br>
          Error: este campo es obligatorio y sólo acepta letras y espacios
        </small>
        <br>
        <input type="text" id="phone" #phone="ngModel" [(ngModel)]="contact.phone" class="input-style m-2" placeholder="Teléfono" pattern="[0-9]+" required>
        <small *ngIf="!phone.valid && phone.touched" class="text-white">
          <br>
          Error: este campo es obligatorio y sólo acepta números
        </small>
        <br>
        <input type="email" id="email" #email="ngModel" [(ngModel)]="contact.email" class="input-style m-2" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
        <small *ngIf="!email.valid && email.touched" class="text-white">
          <br>
          Error: este campo es obligatorio y sólo acepta email
        </small>
        <br>
        <textarea id="commentary" #commentary="ngModel" [(ngModel)]="contact.commentary" class="input-style m-4" placeholder="Mensaje" pattern="[áéíóúÁÉÍÓÚñÑa-zA-Z0-9 ]+"></textarea>
        <small *ngIf="!commentary.valid && commentary.touched" class="text-white">
          <br>
          Error: Este sólo acepta letras y números
        </small>
        <br>
        <button (click)="saveContact()" class="button-style">Enviar</button>
      </div>
    </div>
    <div align="center" class="col-md-6">
      <br>
      <br>
      <table>
        <tr>
          <td valign="top">
            <img src="/assets/img/icon-location.png" width="40px">
          </td>
          <td>
            <h3 class="text-white">ESPAÑA</h3>
            <h5 class="text-white">
              C/ Santa Engracia n° 17 4 Dcha, 28010 Madrid, <br> España.<br>
            </h5>
          </td>
        </tr>
        <tr>
          <td>
            <img src="/assets/img/icon-phone.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              T +34 91 561 90 52 - Móv. +34 691 655 780
            </h5>
          </td>
        </tr>
        <br>
        <br>
        <tr>
          <td valign="top">
            <img src="/assets/img/icon-location.png" width="40px">
          </td>
          <td>
            <h3 class="text-white">MÉXICO</h3>
            <h5 class="text-white">
              Avenida Sonora 100, Roma Norte,
              <br>
              Cuauhtémoc, C.P. 06700, CDMX<br>
            </h5>
          </td>
        </tr>
        <tr>
          <td>
            <img src="/assets/img/icon-phone.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              +52 5571587854
            </h5>
          </td>
        </tr>
        <br>
        <br>
        <tr>
          <td>
            <img src="/assets/img/icon-mailing.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              asesoria@cltraining.es
            </h5>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div id="section">
  <div id="map-container" class="z-depth-1-half map-container" style="height: 350px; width: 100%;"><iframe title="Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.038355674779!2d-3.6992024842160394!3d40.43014957936364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42288dedbf8e01%3A0xfdc7d0bae0859fe0!2sCorporate+Language+Training!5e0!3m2!1ses!2smx!4v1543971226306" frameborder="0" style="width: 100%; height: 100%;"></iframe>
  </div>
</div>
<div align="center" id="footer" class="bg-color-red text-white">
  <div class="row">
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/']" (click)="uncheck()" class="as-none text-white">Inicio</a></p>
      <p><a [routerLink]="['/metodologia']" (click)="uncheck()" class="as-none text-white">Metodología</a></p>
      <p><a [routerLink]="['/programas-de-ensenanza']" (click)="uncheck()" class="as-none text-white">Programas <br> de enseñanza</a></p>
    </div>
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/traduccion-interpretacion']" (click)="uncheck()" class="as-none text-white">Traducción e <br> interpretación</a></p>
      <p><a [routerLink]="['/otros-servicios']" (click)="uncheck()" class="as-none text-white">Otros servicios</a></p>
      <p><a [routerLink]="['/nosotros']" (click)="uncheck()" class="as-none text-white">Nosotros</a></p>
    </div>
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/trabaja-con-nosotros']" (click)="uncheck()" class="as-none text-white">Trabaja con <br> nosotros</a></p>
      <p><a [routerLink]="['/contacto']" (click)="uncheck()" class="as-none text-white">Contacto</a></p>
    </div>
  </div>
  <label class="mt-3">CL Training 2020 &reg;</label>
</div>
