<navbarenglish></navbarenglish>
<div id="section" class="animated-low gone">
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" style="margin-top: -50px;">
    <ol class="carousel-indicators">
    	<li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        	<img class="d-block w-100" src="/assets/img/banner-1.png" alt="First slide">
        	<div class="carousel-caption d-none d-md-block">
  	  		<h2 class="display-4">All languages with national and international coverage</h2>
  	    	<!-- <p>...</p> -->
  	  	</div>
      </div>
      <div class="carousel-item">
        	<img class="d-block w-100" src="/assets/img/banner-2.png" alt="Second slide">
      	<div class="carousel-caption d-none d-md-block">
  	    	<h5 class="display-4">More than 200 high-level teachers and 4.500 class hours per month</h5>
          <!-- <p>...</p> -->
  	  	</div>
      </div>
      <div class="carousel-item">
        	<img class="d-block w-100" src="/assets/img/banner-3.png" alt="Third slide">
      	<div class="carousel-caption d-none d-md-block">
  	    	<h5 class="display-4">More than 30 years providing language solutions to companies</h5>
  	    	<!-- <p>...</p> -->
  	  	</div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
<div id="article" class="animated-low">
  <div class="row">
    <div class="col-md-6">
      <h5 align="justify" class="text-secondary">
        <br>
        <br>
        We offer quality language solutions for companies thanks to our comprehensive methodology that combines online tools (CL platform) with face-to-face and telephone/skype sessions. 100% of our teaching meets the requirements demanded by FUNDAE
      </h5>
    </div>
    <div class="col-md-6">
      <img src="/assets/img/fundacion-estatal.png" width="250px">
    </div>
  </div>
  <br>
  <br>
  <div class="card-deck mt-5">
    <div class="card" style="background-image: url('/assets/img/programas-de-ensenanza.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
      <div class="bg-color-red">
        <h5 class="py-2 text-white">Teaching programmes</h5>
      </div>
      <div class="card-body">
        <p class="card-text text-white">Learn any language with the methodology that best suits your daily life: blended, online, face-to-face, crash course, telephone, etc.</p>
        <br>
      </div>
      <small align="right" class="m-3 text-white"><a [routerLink]="['/teaching-programmes']" (click)="uncheck()" class="text-white as-none">MÁS ></a></small>
    </div>
    <div class="card" style="background-image: url('/assets/img/traduccioneinterpretacion.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
      <div class="bg-color-red">
        <h5 class="py-2 text-white">Translation and interpretation</h5>
      </div>
      <div class="card-body">
        <p class="card-text text-white">We specialise in general, commercial, technical, legal, financial, literary, advertising and sworn translation and interpretation.</p>
        <br>
      </div>
      <small align="right" class="m-3 text-white"><a [routerLink]="['/translation-interpretation']" (click)="uncheck()" class="text-white as-none">MÁS ></a></small>
    </div>
    <div class="card" style="background-image: url('/assets/img/otros-servicios.png'); no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
      <div class="bg-color-red">
        <h5 class="py-2 text-white">Other services</h5>
      </div>
      <div class="card-body">
        <p class="card-text text-white">Language audits, support during selection processes, immersions, seminars, workshops, etc.
          <br>
        </p>
      </div>
      <small align="right" class="m-3 text-white"><a [routerLink]="['/other-services']" (click)="uncheck()" class="text-white as-none">MÁS ></a></small>
    </div>
  </div>
</div>
<div id="article" style="background-image: url('/assets/img/circle.png'); background-repeat: repeat;">
  <h2 class="font-weight-bold text-red mt-5 mb-5" style="font-size: 2.5em;">OUR DNA</h2>
  <div class="row px-5">
    <div class="col-md-6 px-lg-5 mb-4">
      <img src="/assets/img/icono-calidad.png" width="100px">
      <h5 class="font-weight-bold mt-3 mb-3 text-secondary">Quality</h5>
      <p align="center" class="px-5 text-secondary">
        We are committed to satisfying every client, no matter what size, implementing our stringent performance controls.
      </p>
    </div>
    <div class="col-md-6 px-lg-5 mb-4">
      <img src="/assets/img/icono-flexibilidad.png" width="100px">
      <h5 class="font-weight-bold mt-3 mb-3 text-secondary">Flexibility</h5>
      <p align="center" class="px-5 text-secondary">
        Thanks to our experience and capacity, we are able to adapt to the needs of our clients.
      </p>
    </div>
    <div class="col-md-6 px-lg-5 mb-4">
      <img src="/assets/img/icono-cercania.png" width="100px">
      <h5 class="font-weight-bold mt-3 mb-3 text-secondary">Approachable</h5>
      <p align="center" class="px-5 text-secondary">
        We engage in every project to ensure success, and can even arrange in-house services at the client’s premises.
      </p>
    </div>
    <div class="col-md-6 px-lg-5 mb-4">
      <img src="/assets/img/icono-especializacion.png" width="100px">
      <h5 class="font-weight-bold mt-3 mb-3 text-secondary">Specialisation</h5>
      <p align="center" class="px-5 text-secondary">
        We deliver effective programmes, focused on what the client really needs.
      </p>
    </div>
  </div>
</div>
<div id="article">
  <h2 class="font-weight-bold text-red mt-5" style="font-size: 2.5em;">CLIENTS</h2>
  <br>
  <br>
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/ac-hoteles.png" width="200px" class="m-4">
              <img src="/assets/img/accenture.png" width="200px" class="m-4">
              <img src="/assets/img/accor.png" width="200px" class="m-4">
              <img src="/assets/img/adif.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/alaina.png" width="200px" class="m-4">
              <img src="/assets/img/camper.png" width="200px" class="m-4">
              <img src="/assets/img/carlton.png" width="200px" class="m-4">
              <img src="/assets/img/creditsuit.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/dunlop.png" width="200px" class="m-4">
              <img src="/assets/img/enagas.png" width="200px" class="m-4">
              <img src="/assets/img/feuvert.png" width="200px" class="m-4">
              <img src="/assets/img/gobierno-espana.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/goodyear.png" width="200px" class="m-4">
              <img src="/assets/img/groupama.png" width="200px" class="m-4">
              <img src="/assets/img/ikea.png" width="200px" class="m-4">
              <img src="/assets/img/inditex.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
            <div class="col-md-12">
              <img src="/assets/img/kraft.png" width="200px" class="m-4">
              <img src="/assets/img/loewe.png" width="200px" class="m-4">
              <img src="/assets/img/louis.png" width="200px" class="m-4">
              <img src="/assets/img/prosegur.png" width="200px" class="m-4">
            </div>
            <div class="col-md-12">
              <img src="/assets/img/santander.png" width="200px" class="m-4">
              <img src="/assets/img/seur.png" width="200px" class="m-4">
              <img src="/assets/img/wanner-bross.png" width="200px" class="m-4">
              <img src="/assets/img/ikea.png" width="200px" class="m-4">
            </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon bg-color-red" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon bg-color-red" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
<div id="article" style="background-image: url('/assets/img/textura-formulario.png');">
  <h2 class="font-weight-bold text-white mt-4 mb-5" style="font-size: 2.5em;">CONTACT</h2>
  <div class="row">
    <div align="center" class="col-md-6 p-4">
      <div class="bg-color-red py-5 rounded-lg shadow-lg">
        <input type="text" id="name" #name="ngModel" [(ngModel)]="contact.name" class="input-style m-2" placeholder="Name" pattern="[áéíóúÁÉÍÓÚñÑa-zA-Z ]+" required>
        <small *ngIf="!name.valid && name.touched" class="text-white">
          <br>
          Error: this field is mandatory and only accepts letters and spaces
        </small>
        <br>
        <input type="text" id="phone" #phone="ngModel" [(ngModel)]="contact.phone" class="input-style m-2" placeholder="Phone" pattern="[0-9]+" required>
        <small *ngIf="!phone.valid && phone.touched" class="text-white">
          <br>
          Error: this field is mandatory and only accepts numbers
        </small>
        <br>
        <input type="email" id="email" #email="ngModel" [(ngModel)]="contact.email" class="input-style m-2" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
        <small *ngIf="!email.valid && email.touched" class="text-white">
          <br>
          Error: this field is mandatory and only accepts email
        </small>
        <br>
        <textarea id="commentary" #commentary="ngModel" [(ngModel)]="contact.commentary" class="input-style m-4" placeholder="Message" pattern="[áéíóúÁÉÍÓÚñÑa-zA-Z0-9 ]+"></textarea>
        <small *ngIf="!commentary.valid && commentary.touched" class="text-white">
          <br>
          Error: This only accepts letters and numbers
        </small>
        <br>
        <button (click)="saveContact()" class="button-style">Send</button>
      </div>
    </div>
    <div align="center" class="col-md-6">
      <br>
      <br>
      <table>
        <tr>
          <td valign="top">
            <img src="/assets/img/icon-location.png" width="40px">
          </td>
          <td>
            <h3 class="text-white">ESPAIN</h3>
            <h5 class="text-white">
              C/ Santa Engracia n° 17 4 Dcha, 28010 Madrid, <br> España.<br>
            </h5>
          </td>
        </tr>
        <tr>
          <td>
            <img src="/assets/img/icon-phone.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              T +34 91 561 90 52 - Cel +34 691 655 780
            </h5>
          </td>
        </tr>
        <br>
        <br>
        <tr>
          <td valign="top">
            <img src="/assets/img/icon-location.png" width="40px">
          </td>
          <td>
            <h3 class="text-white">MÉXICO</h3>
            <h5 class="text-white">
              Avenida Sonora 100, Roma Norte,
              <br>
              Cuauhtémoc, C.P. 06700, CDMX<br>
            </h5>
          </td>
        </tr>
        <tr>
          <td>
            <img src="/assets/img/icon-phone.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              +52 5571587854
            </h5>
          </td>
        </tr>
        <br>
        <br>
        <tr>
          <td>
            <img src="/assets/img/icon-mailing.png" width="40px">
          </td>
          <td>
            <h5 class="text-white">
              asesoria@cltraining.es
            </h5>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div id="section">
  <div id="map-container" class="z-depth-1-half map-container" style="height: 350px; width: 100%;"><iframe title="Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.038355674779!2d-3.6992024842160394!3d40.43014957936364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42288dedbf8e01%3A0xfdc7d0bae0859fe0!2sCorporate+Language+Training!5e0!3m2!1ses!2smx!4v1543971226306" frameborder="0" style="width: 100%; height: 100%;"></iframe>
  </div>
</div>
<div align="center" id="footer" class="bg-color-red text-white">
  <div class="row">
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/en']" (click)="uncheck()" class="as-none text-white">Home</a></p>
      <p><a [routerLink]="['/methodology']" (click)="uncheck()" class="as-none text-white">Methodology</a></p>
      <p><a [routerLink]="['/teaching-programmes']" (click)="uncheck()" class="as-none text-white">Teaching <br> programmes</a></p>
    </div>
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/translation-interpretation']" (click)="uncheck()" class="as-none text-white">Translation e <br> interpretation</a></p>
      <p><a [routerLink]="['/other-services']" (click)="uncheck()" class="as-none text-white">Other sevices</a></p>
      <p><a [routerLink]="['/about-us']" (click)="uncheck()" class="as-none text-white">About us</a></p>
    </div>
    <div align="center" class="p-4 col-md-4 text-white">
      <p><a [routerLink]="['/work-with-us']" (click)="uncheck()" class="as-none text-white">Work with us</a></p>
      <p><a [routerLink]="['/contact']" (click)="uncheck()" class="as-none text-white">Contact</a></p>
    </div>
  </div>
  <label class="mt-3">CL Training 2020 &reg;</label>
</div>
